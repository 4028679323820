import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {clientAPI} from "./axiosAnstance";
import axios from "axios";
import {SERVER_URL} from "../../../serverConfig";
import {getAuthHeader} from "../../../jwt/config";
import fileDownload from "js-file-download";





//
export const listHajjDemandeAPI = createAsyncThunk(
  "hajj/listHajjDemandeAPI",
  async (data) => {
    const url = `/api/hajj/getDemandes`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);
export const traiteDemandeHajjAPI = createAsyncThunk(
  "hajj/traiteDemandeHajjAPI",
  async ({ id }, thunkAPI) => {
    try {
      const url = `/api/hajj/traiteDemande/${id}`;
      const response = await clientAPI.post(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const listHajjDemandeTraiteAPI = createAsyncThunk(
  "hajj/listHajjDemandeTraiteAPI",
  async (data) => {
    const url = `/api/hajj/listeDemandeTraite`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);
export const incompteteHajjAPI = createAsyncThunk(
  "hajj/incompteteHajjAPI",
  async (data) => {
    const formData = new FormData();
    console.log('data is ::: ',data)
    formData.append("idDemande", data.idDemande);
    formData.append("motifRefus", data.motifRefus);
    console.log('Formater data ::: ',formData)
    const response = await clientAPI.post("/api/hajj/icompleteDemande", formData);
    return response.data;
  }
);
export const saveDemandeHajjAPI = createAsyncThunk(
  "hajj/saveDemandeHajjAPI",
  async (data) => {
    try {
      const response = await clientAPI.post("/api/hajj/saveDemande", data);
      return response.data;
    } catch (error) {
      return 'il est un probléme au niveau de la API';
    }
  }
);

export const valideDemandeHajjAPI = createAsyncThunk(
  "hajj/valideDemandeHajjAPI",
  async ({ id, action }, thunkAPI) => {
    try {
      const url = `/api/hajj/valide/${id}`;
      const response = await clientAPI.post(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const listDemandeFilterHajjAPI = createAsyncThunk(
  "hajj/listDemandeFilterHajjAPI",
  async (data) => {
    console.log('le data est :: ',data)
    //const url = `/api/colonie/listColonie`;
    const response = await clientAPI.post("/api/hajj/filter",data);
    return response.data;
  }
);

export const refuserHajjAPI = createAsyncThunk(
  "hajj/refuserHajjAPI",
  async (data) => {
    const formData = new FormData();
    console.log('data is ::: ',data)
    formData.append("idDemande", data.idDemande);
    formData.append("motifRefus", data.motifRefus);
    console.log('Formater data ::: ',formData)
    const response = await clientAPI.post("/api/hajj/refusDemande", formData);
    return response.data;
  }
);
export const listTypeFichierSavedHajjAPI = createAsyncThunk(
  "hajj/listTypeFichierSavedHajjAPI",
  async (idDemande) => {
    const response = await clientAPI.get("/api/hajj/listeTypeFichierSaved/" + idDemande);
    return response.data;
  }
);

export const getFileByDemandeAndTypeHajj = createAsyncThunk(
  "hajj/getFileByDemandeAndTypeHajj",
  async (data) => {
    return axios
      .get(
        SERVER_URL +
        `/api/hajj/getFileByDemandeAndType/${data.idDemande}/${data.id_type_fichier}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((res) => {
        return res.data;
      });
  }
);


export const saveFichierHajjAPI = createAsyncThunk(
  "hajj/saveFichierHajjAPI",
  async (data) => {
    const fichi = JSON.stringify(data.toSave);

    const blob = new Blob([fichi], {
      type: "application/json",
    });

    var formData = new FormData();

    var theFile = data.file;

    formData.append("file", theFile);
    formData.append("fileMeta", blob);
    let token = localStorage.getItem("token");

    const response = axios.post(SERVER_URL + `/api/hajj/saveFichier`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      Accept: "application/json",
    });
    return (await response).data;
  }
);

const Hajj = createSlice({
  name: "hajj",
  initialState: {
    listeDemande : [],
    listeDemandeTraite : [],
    listeTypeFichierSaved : [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listHajjDemandeAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeDemande = action.payload;
      })
      .addCase(listHajjDemandeTraiteAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeDemandeTraite = action.payload;
      })
      .addCase(listTypeFichierSavedHajjAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeTypeFichierSaved = action.payload;
      })
      .addCase(listDemandeFilterHajjAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeDemande = action.payload;
      })

  },
});


export const {} = Hajj.actions;

export default Hajj.reducer;
