import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import fileDownload from "js-file-download";
import { getAuthHeader } from "src/jwt/config";
import { SERVER_URL } from "src/serverConfig";
import { clientAPI } from "./axiosAnstance";

export const getListOperationPrecompteAPI = createAsyncThunk(
  "precompte/getListOperationPrecompte",
  async (data) => {
    const response = await clientAPI.get("/api/operation_precompte/list", data);
    return response.data;
  }
);

export const initiatePrecompteOperationAPI = createAsyncThunk(
  "precompte/initiatePrecompteOperation",
  async (data) => {
    const response = await clientAPI.post(
      "/api/operation_precompte/initiat",
      data
    );
    return response.data;
  }
);

export const loadTGRCofirmationFileAPI = createAsyncThunk(
  "precompte/loadConfirmationTgrFile",
  async (typePrecompte) => {
    const response = await clientAPI.get(
      "/api/operation_precompte/load_confirmation_resp/" + typePrecompte
    );
    return response.data;
  }
);

export const loadReservationResponseAPI = createAsyncThunk(
  "precompte/loadreservationResponse",
  async (typePrecompte) => {

    return axios
      .get(
        SERVER_URL +
          `/api/operation_precompte/load_reservation_resp/${typePrecompte.id}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((res) => {
        fileDownload(res.data, typePrecompte.libelle + ".pre");
      });
  }
);

export const generateReservationPrecompteFileAPI = createAsyncThunk(
  "precompte/generateReservationPrecompteFile",
  async (typePrecompte) => {
    return axios
      .get(
        SERVER_URL +
          `/api/operation_precompte/generate_reservation_file/${typePrecompte.id}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((res) => {
        fileDownload(res.data, typePrecompte.libelle + ".con");
      });
  }
);

const PrecompteSlice = createSlice({
  name: "precompte",
  initialState: {
    initiatOperationStatus: null,
    precompteOperation: null,
    listOpeartions: [],
    reservationPrecompte: null,
    loadReservationResp: null,
  },
  reducers: {},
  extraReducers: {
    // ---------------- list operations ------------------

    [getListOperationPrecompteAPI.fulfilled]: (state, action) => {
      state.listOpeartions = action.payload;
    },

    // --------- initial operations precompte

    [initiatePrecompteOperationAPI.pending]: (state, action) => {
      state.initiatOperationStatus = "pending";
      state.precompteOperation = null;
    },
    [initiatePrecompteOperationAPI.fulfilled]: (state, action) => {
      state.initiatOperationStatus = "fulfilled";
      state.precompteOperation = action.payload;
    },
    [initiatePrecompteOperationAPI.rejected]: (state, action) => {
      state.initiatOperationStatus = "rejected";
    },

    // ------------  Load reservation response -----------

    [loadReservationResponseAPI.fulfilled]: (state, action) => {
      state.loadReservationResp = action.payload;
    },

    // ------------ generate reservation file -------------

    [generateReservationPrecompteFileAPI.fulfilled]: (state, action) => {
      state.reservationPrecompte = action.payload;
    },
  },
});

export const initiatOperationStatusSelector = (state) =>
  state.precompte.initiatOperationStatus;

export const {} = PrecompteSlice.actions;

export default PrecompteSlice.reducer;
