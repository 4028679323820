import { createAsyncThunk, createReducer, createSlice } from "@reduxjs/toolkit";
import { clientAPI } from "./axiosAnstance";


export const getAgenceUrbainAPI = createAsyncThunk("params/agenceurbain", async () => {
  const response = await clientAPI.get("/api/agenceurbain/list");
  return response.data;
});

export const getMois = createAsyncThunk("params/getMois", async () => {
  const response = await clientAPI.get("/api/estivage/listeMois");
  console.log('la liste des mos :: ',response.data)
  return response.data;
});

export const addAgenceUrbainAPI = createAsyncThunk(
  "params/addAgenceUrbain",
  async (data) => {
    const response = await clientAPI.post("/api/agenceurbain/save", data);
    return response.data;
  }
);

export const deleteAgenceUrbainAPI = createAsyncThunk(
  "params/deleteAgenceUrbain",
  async (id) => {
    const response = await clientAPI.delete("/api/agenceurbain/" + id);
    return response.data;
  }
);

export const getVilleAPI = createAsyncThunk("params/getVilles", async () => {
  const response = await clientAPI.get("/api/ville/list");
  return response.data;
});

export const addVilleAPI = createAsyncThunk(
  "params/addVilles",
  async (data) => {
    const response = await clientAPI.post("/api/ville/save", data);
    return response.data;
  }
);

export const deleteVilleAPI = createAsyncThunk(
  "params/deleteVilles",
  async (id) => {
    const response = await clientAPI.delete("/api/ville/" + id);
    return response.data;
  }
);

export const getImputationsAPI = createAsyncThunk(
  "params/getImputations",
  async () => {
    const response = await clientAPI.get("/api/imputation/list");
    return response.data;
  }
);

export const addImputationAPI = createAsyncThunk(
  "params/addImputation",
  async (data) => {
    const response = await clientAPI.post("/api/imputation/save", data);
    return response.data;
  }
);

export const deleteImputationAPI = createAsyncThunk(
  "params/deleteImputation",
  async (id) => {
    const response = await clientAPI.delete("/api/imputation/" + id);
    return response.data;
  }
);

export const getStatutsAPI = createAsyncThunk("params/getStatuts", async () => {
  const response = await clientAPI.get("/api/statut/list");
  return response.data;
});

export const addStatutAPI = createAsyncThunk(
  "params/addStatut",
  async (data) => {
    const response = await clientAPI.post("/api/statut/save", data);
    return response.data;
  }
);

export const deleteStatutAPI = createAsyncThunk(
  "params/deleteStatut",
  async (id) => {
    const response = await clientAPI.delete("/api/statut/" + id);
    return response.data;
  }
);

// ***

export const getOriginsAPI = createAsyncThunk("params/Origin", async () => {
  const response = await clientAPI.get("/api/workorigin/list");
  const res =
    response.data &&
    response.data.map((e) => ({
      id: e.id_origin,
      libelle: e.libelle,
    }));
  return res;
});

export const addOriginAPI = createAsyncThunk(
  "params/addOrigin",
  async (data) => {
    const response = await clientAPI.post("/api/workorigin/save", data);
    return response.data;
  }
);

export const deleteOriginAPI = createAsyncThunk(
  "params/deleteOrigin",
  async (id) => {
    const response = await clientAPI.delete("/api/workorigin/" + id);
    return response.data;
  }
);

// ***

export const getOrganizmOriginListAPI = createAsyncThunk(
  "params/getOrganizmOrigin",
  async () => {
    const response = await clientAPI.get("/api/organizmorigin/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_organizmOrigin,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const addOrganizmOriginAPI = createAsyncThunk(
  "params/addOrganizmOrigin",
  async (data) => {
    const response = await clientAPI.post("/api/organizmorigin/save", data);
    return response.data;
  }
);

export const deleteOrganizmOriginAPI = createAsyncThunk(
  "params/deleteOrganizmOrigin",
  async (id) => {
    const response = await clientAPI.delete("/api/organizmorigin/" + id);
    return response.data;
  }
);

// ***
export const getPaysAPI = createAsyncThunk("params/getPays", async () => {
  const response = await clientAPI.get("/api/pays/list");
  return response.data;
});

export const getFonctionsAPI = createAsyncThunk(
  "params/getFonctions",
  async () => {
    const response = await clientAPI.get("/api/fonction/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_fonction,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const addFonctionAPI = createAsyncThunk(
  "params/addFonction",
  async (data) => {
    const response = await clientAPI.post("/api/fonction/save", data);
    return response.data;
  }
);

export const deleteFonctionAPI = createAsyncThunk(
  "params/deleteFonction",
  async (id) => {
    const response = await clientAPI.delete("/api/fonction/" + id);
    return response.data;
  }
);

export const getGradesAPI = createAsyncThunk("params/getGrades", async () => {
  const response = await clientAPI.get("/api/grade/list");
  const res =
    response.data &&
    response.data.map((e) => ({
      id: e.id_grade,
      libelle: e.libelle,
      code: e.code,
      montant_cotisation: e.montant_cotisation,
    }));
  return res;
});

export const addGradeAPI = createAsyncThunk("params/addGrade", async (data) => {
  const response = await clientAPI.post("/api/grade/save", data);
  return response.data;
});

export const deleteGradeAPI = createAsyncThunk(
  "params/deleteGrade",
  async (id) => {
    const response = await clientAPI.post("/api/grade/" + id);
    return response.data;
  }
);

export const getEchellesAPI = createAsyncThunk(
  "params/getEchelles",
  async () => {
    const response = await clientAPI.get("/api/echelle/list");
    return response.data;
  }
);

export const getAffectationsAPI = createAsyncThunk(
  "params/getAffectations",
  async () => {
    const response = await clientAPI.get("/api/affectation/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_affectation,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const addAffectationAPI = createAsyncThunk(
  "params/addAffectation",
  async (data) => {
    const response = await clientAPI.post("/api/affectation/save", data);
    return response.data;
  }
);

export const deleteAffectationAPI = createAsyncThunk(
  "params/deleteAffectation",
  async (id) => {
    const response = await clientAPI.delete("/api/affectation/" + id);
    return response.data;
  }
);

export const getRegionsAPI = createAsyncThunk("params/getRegions", async () => {
  const response = await clientAPI.get("/api/region/list");
  const res =
    response.data &&
    response.data.map((e) => ({ id: e.id_region, libelle: e.region_fr }));
  return res;
});

export const getProvincesAPI = createAsyncThunk(
  "params/getProvinces",
  async (idRegion) => {
    const response = await clientAPI.get("/api/province/list/" + idRegion);
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_province,
        libelle: e.province_fr,
      }));
    return res;
  }
);

export const getCommunesAPI = createAsyncThunk(
  "params/getCommunes",
  async (idProvince) => {
    const response = await clientAPI.get("/api/commune/list/" + idProvince);
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_commune,
        libelle: e.commune_fr,
      }));
    return res;
  }
);

export const getTypeAdherentsAPI = createAsyncThunk(
  "params/getTypeAdherents",
  async () => {
    const response = await clientAPI.get("/api/type_adherent/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_type_adherent,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const addTypeAdherentsAPI = createAsyncThunk(
  "params/addTypeAdherents",
  async (data) => {
    const response = await clientAPI.post("/api/type_adherent/save", data);
    return response.data;
  }
);

export const deleteTypeAdherentsAPI = createAsyncThunk(
  "params/deleteTypeAdherents",
  async (id) => {
    const response = await clientAPI.delete("/api/type_adherent/" + id);
    return response.data;
  }
);

export const getModesReglementsAPI = createAsyncThunk(
  "params/getModesReglements",
  async () => {
    const response = await clientAPI.get("/api/mode_reglement/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_mode_reglement,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const addModeReglementAPI = createAsyncThunk(
  "params/addModeReglement",
  async (data) => {
    const response = await clientAPI.post("/api/mode_reglement/save", data);
    return response.data;
  }
);

export const deleteModeReglementAPI = createAsyncThunk(
  "params/deleteModeReglement",
  async (id) => {
    const response = await clientAPI.delete("/api/mode_reglement/" + id);
    return response.data;
  }
);

export const getSituationsFamilialeAPI = createAsyncThunk(
  "params/getSituationsFamiliale",
  async () => {
    const response = await clientAPI.get("/api/situation_familiale/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_situation_familiale,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const getTypeFichierToLoadInCotisationAPI = createAsyncThunk(
  "params/getTypeFichierToLoadInCotisation",
  async () => {
    const response = await clientAPI.get("/api/type_cotisation/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_type_cotisation,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const getTypeOperationMoisAPI = createAsyncThunk(
  "params/getTypeOperationMois",
  async () => {
    const response = await clientAPI.get("/api/code_operation_mois/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.idCode,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const getTypeOrganismeAPI = createAsyncThunk(
  "params/getTypeOrganisme",
  async () => {
    const response = await clientAPI.get("/api/type_organisme/list");
    return response.data;
  }
);

export const getListTypeFichiersAPI = createAsyncThunk(
  "params/getListTypeFichier",
  async () => {
    const response = await clientAPI.get("/api/type_fichier/list/");
    return response.data;
  }
);

export const addTypeFichiersAPI = createAsyncThunk(
  "params/addTypeFichier",
  async (data) => {
    const response = await clientAPI.post("/api/type_fichier/save", data);
    return response.data;
  }
);

export const deleteTypeFichiersAPI = createAsyncThunk(
  "params/deleteTypeFichier",
  async (id) => {
    const response = await clientAPI.delete("/api/type_fichier/" + id);
    return response.data;
  }
);

export const getTypeAttestationAPI = createAsyncThunk(
  "params/getTypeAttestation",
  async () => {
    const response = await clientAPI.get("/api/type_attestation/list");
    return response.data;
  }
);

export const addTypeAttestationAPI = createAsyncThunk(
  "params/addTypeAttestation",
  async (data) => {
    const response = await clientAPI.post("/api/type_attestation/save", data);
    return response.data;
  }
);

export const deleteTypeAttestationAPI = createAsyncThunk(
  "params/deleteTypeAttestation",
  async (id) => {
    const response = await clientAPI.delete("/api/type_attestation/" + id);
    return response.data;
  }
);

const initialState = {
  sidebarShow: "responsive",
};
export const navRecuer = createReducer(initialState, (builder) => {
  builder.addCase("set", (state, action) => {
    return { ...state, ...action };
  });
});

const Params = createSlice({
  name: "params",
  initialState: {
    data: {},
    imputations: [],
    status: [],
    origins: [],
    organizmOrigins: [],
    pays: [],
    villes: [],
    fonctions: [],
    grades: [],
    echelles: [],
    affectations: [],
    listeMois: [],
    regions: [],
    provinces: [],
    communes: [],
    typesAdherent: [],
    modesReglement: [],
    situationsFamiliale: [],
    typeCotisationFichiers: [],
    typeOperationMois: [],
    typeOrganisme: [],
    typeFichiers: [],
    typeAttestations: [],
    aganceUrbains: [],
    isActive: null,
  },
  reducers: {},
  extraReducers: {
    [getAgenceUrbainAPI.fulfilled]: (state, action) => {
      state.aganceUrbains = action.payload;
    },

    [addAgenceUrbainAPI.fulfilled]: (state, action) => {
      //state.aganceUrbains.push(action.payload);
    },
    [deleteAgenceUrbainAPI.fulfilled]: (state, action) => {
      //state.aganceUrbains.push(action.payload);
      console.log("delete etat " + action.payload);
    },

    [getVilleAPI.fulfilled]: (state, action) => {
      state.villes = action.payload;
    },
    [getMois.fulfilled]: (state, action) => {
      state.listeMois = action.payload;
      console.log('le size de la liste est :: ',action.payload);
    },

    [addVilleAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
    },
    [deleteVilleAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      // console.log("delete etat " + action.payload);
    },
    [getImputationsAPI.fulfilled]: (state, action) => {
      state.imputations = action.payload;
    },
    [getStatutsAPI.fulfilled]: (state, action) => {
      state.status = action.payload;
    },
    [getOriginsAPI.fulfilled]: (state, action) => {
      state.origins = action.payload;
    },
    [getOrganizmOriginListAPI.fulfilled]: (state, action) => {
      state.organizmOrigins = action.payload;
    },

    [getPaysAPI.fulfilled]: (state, action) => {
      // let filtered = action.payload.find((e) => e.libelle == "Maroc");
      // state.pays = [
      //   filtered,
      //   action.payload.filter((e) => e.libelle != "Maroc"),
      // ];
      state.pays = action.payload;
    },
    [getFonctionsAPI.fulfilled]: (state, action) => {
      state.fonctions = action.payload;
    },
    [getGradesAPI.fulfilled]: (state, action) => {
      state.grades = action.payload;
    },
    [getEchellesAPI.fulfilled]: (state, action) => {
      state.echelles = action.payload;
    },
    [getAffectationsAPI.fulfilled]: (state, action) => {
      state.affectations = action.payload;
    },
    [getRegionsAPI.fulfilled]: (state, action) => {
      state.regions = action.payload;
    },
    [getProvincesAPI.fulfilled]: (state, action) => {
      state.provinces = action.payload;
    },
    [getCommunesAPI.fulfilled]: (state, action) => {
      state.communes = action.payload;
    },
    [getTypeAdherentsAPI.fulfilled]: (state, action) => {
      state.typesAdherent = action.payload;
    },
    [getModesReglementsAPI.fulfilled]: (state, action) => {
      state.modesReglement = action.payload;
    },
    [getSituationsFamilialeAPI.fulfilled]: (state, action) => {
      state.situationsFamiliale = action.payload;
    },
    [getTypeFichierToLoadInCotisationAPI.fulfilled]: (state, action) => {
      state.typeCotisationFichiers = action.payload;
    },
    [getTypeOperationMoisAPI.fulfilled]: (state, action) => {
      state.typeOperationMois = action.payload;
    },
    [getTypeOrganismeAPI.fulfilled]: (state, action) => {
      state.typeOrganisme = action.payload;
    },
    [getListTypeFichiersAPI.fulfilled]: (state, action) => {
      state.typeFichiers = action.payload;
    },
    [addTypeFichiersAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("add etat ", action.payload);
    },
    [deleteTypeFichiersAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("delete etat ", action.payload);
    },
    [addTypeAdherentsAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("add etat ", action.payload);
    },
    [deleteTypeAdherentsAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("delete etat ", action.payload);
    },
    [addModeReglementAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("add etat ", action.payload);
    },
    [deleteModeReglementAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("delete etat ", action.payload);
    },
    [getTypeAttestationAPI.fulfilled]: (state, action) => {
      state.typeAttestations = action.payload;
    },
  },
});

export const getVilleNameById = (idVille) => (state) => {
  const ville = state.params.villes.find((e) => e.id == idVille);
  return ville ? ville.libelle : "----";
};

export const { } = Params.actions;

export default Params.reducer;
